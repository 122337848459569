* {
  box-sizing: border-box;
}

body {
  margin: 0;
  padding: 0;
}

.admin-wrapper {
  --colors-white: #FFFFFF;
  --colors-grey-dark: #04281E;
  --colors-grey-light: #44594E;
  --colors-brown-red: #AC857A;
  --colors-brown-dark: #927B6F;
  --colors-brown-light: #B7A09A;
  --colors-green-main: #749E97;
  --colors-green-alternative: #1AC5A8;
  display: flex;
  height: 100vh;
  width: 100vw;
  overflow-y: hidden;
}

.admin-wrapper > div {
  flex: 1;
}

.form-list {
  height: 100%;
  padding: 30px 40px;
  width: 100%;
}

.sidebar-wrapper {
  background: #27292C;
  color: #C5CACF;
  height: 100%;
  padding-top: 35px;
  width: 300px;
  max-width: 300px;
  flex-shrink: 0;
}

